import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ReadPortalContainer from "./containers/readPortalContainer";

const theme = createMuiTheme({
    typography: {
        useNextVariants: true
    },
    overrides: {
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottomColor: '#fad201',
                    color: '#ffffff'
                },
                '&:after': {
                    borderBottomColor: '#fad201',
                    color: '#ffffff'
                },
            },

        },
        MuiInputLabel: {
                root: {
                   "&$focused": {
                        color: "#fad201"
                    }
                }
        },
        MuiButton: {
           root: {
              backgroundColor: '#fad201',
               "&:hover": {
                  backgroundColor: '#000',
                   color: '#fad201'
               }
           }
        }
    }
});

ReactDOM.render(
    <Router>
        <MuiThemeProvider theme={theme}>
            <Route path={'/read'} component={ReadPortalContainer} />
            <Route path={'/'} component={App} />

         </MuiThemeProvider>
    </Router>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
