import React from 'react'
import { Typography, withStyles } from '@material-ui/core';




const styles = theme => ({
    btn: {
        borderRadius: '35px',
        display: 'block',
        marginRight: 'auto',
        marginLeft: 'auto',
        textTransform: 'none',
        // fontSize: '1.2em'
    },
    background: {
        background: '#343332',
        paddingTop: '2%',
        paddingBottom: '3%',
        width: '100vw'
    },
    text: {
        color: '#fff'
    },
    coloredText: {
        color: '#fad201'
    }
})


const Contact = (props) => {
    const { classes } = props;
    return (
        <div className={classes.background}>
            <Typography className={classes.text} variant={"h5"} align={"center"}>Phone: <strong className={classes.coloredText}> 412-345-3398</strong> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  Email: <strong className={classes.coloredText}> info@roadbotics.com </strong> </Typography>
        </div>
    )
}

export default withStyles(styles)(Contact)

