import React from 'react';
import ReferBar from '../components/referBar';
import firebase from '../config';

class ReferBarContainer extends React.Component{
    constructor(){
        super();
        this.state = {
            recipients: [],
            from: '',
            name: '',
            message: '',
            open: false
        }
    }



    addARecipient = () => {
        let listOfTos = [...this.state.recipients];
        listOfTos.push({name: `${this.state.name}`, message: `${this.state.message}`});
        this.setState({
            ...this.state,
            recipients: listOfTos,
            name: ''
        })
    };

    handleFrom = e => {
        this.setState({
            ...this.state,
            from: e.target.value
        })
    };

    handleName = e => {
        this.setState({
            ...this.state,
            name: e.target.value
        })
    };

    handleMessage = e => {
        this.setState({
            ...this.state,
            message: e.target.value
        })
    };

    submit = () => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
         if(re.test(String(this.state.name).toLowerCase()) &&
         re.test(String(this.state.from).toLowerCase())) {

             let newName = this.state.name.replace(/\./g, '-');
             let newFrom = this.state.from.replace(/\./g, '-');
             let message = {
                 receiver: this.state.name,
                 message: this.state.message,
                 sender: this.state.from
             };

             firebase.database().ref(`messages/${newFrom}/${newName}`).update(message)
             this.setState({
                 recipients: [],
                 from: '',
                 name: '',
                 message: '',
                 open: true
             })
             setTimeout(() => {
                 this.setState({
                     ...this.state,
                     open:false
                 })
             }, 3000)
         } else {
             alert("Please use valid email addresses");
         }

    };

    render(){
        return(
            <ReferBar
                open={this.state.open}
                submit={this.submit}
                message={this.state.message}
                handleMessage={this.handleMessage}
                from={this.state.from}
                name={this.state.name}
                handleFrom={this.handleFrom}
                handleName={this.handleName}
            />
        )
    }
}

export default ReferBarContainer;
