import React from 'react';
import Login from '../components/login';







class LoginContainer extends React.Component{

    constructor(){
        super();
        this.state = {
            email: '',
            password: ''
        }
    }

    handleEmail = e => {
        this.setState({
            ...this.state,
            email: e.target.value
        })
    };

    handlePassword = e => {
        this.setState({
            ...this.state,
            password: e.target.value
        })
    };

    submit = (e) => {
        e.preventDefault();
        this.props.login(this.state.email, this.state.password);
    };





    render(){
        return(
            <Login handleEmail={this.handleEmail} handlePassword={this.handlePassword} email={this.state.email} password={this.state.password} submit={this.submit}/>
        )
    }

}

export default LoginContainer;
