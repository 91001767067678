import React from 'react';
import left from '../images/bgleftmobile.png';
import right from '../images/bgrightmobile.png';
import { Typography, Hidden } from '@material-ui/core';

const styles = {
    left:{
        // position: 'absolute',
        // marginTop: '-1%',
        // marginLeft: '-1%',
    },
    right: {
        // position: 'absolute',
        // marginLeft: '17%',
        // marginTop: '-3%'
        float: 'right',
    },
    box: {
        // width: '20%',
        // display: 'block',
        // background: 'rgba(255,255,255,.2)',
        // marginLeft: '5%'
        // paddingBottom: '30%',
        marginBottom: '10%'
    },
    text: {
        color: '#fff',
        marginTop: '-15%',
        marginBottom: '-15%',
        paddingTop: '5%',
        paddingBottom: '5%',
        marginLeft: '5%',
        marginRight: '5%',
        lineHeight: '115%',
        zIndex: 10,
        fontSize: '1.2em'
    },
    text2: {
        color: '#fff',
        marginTop: '-10%',
        marginBottom: '-10%',
        paddingTop: '5%',
        paddingBottom: '5%',
        // fontSize: '1.4em',
        lineHeight: '150%',
        zIndex: 10,
        marginLeft: '5%',
        marginRight: '5%',
        fontSize: '1.2em'

    }
};



const ExplainerBox = ({text}) => {
    return (
        <div style={styles.box}>
            <img style={styles.left} src={left} alt={"left"}/>
                <div style={styles.centerBox}>
                    <Hidden mdUp>
                        <Typography style={styles.text} variant={'body1'}>{text}</Typography>
                    </Hidden>
                    <Hidden mdDown>
                        <Typography style={styles.text2} variant={'body1'}>{text}</Typography>
                    </Hidden>
                </div>
            <img style={styles.right} src={right} alt={"right"}/>
        </div>
    )
};

export default ExplainerBox;
