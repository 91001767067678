import React, { Component } from 'react';
import './App.css';
import ReferBarContainer from "./containers/referBarContainer";
import ReferredContainer from './containers/referredContainer';
import background from './images/cure300.jpg';
import whitelogo from './images/whiteLogo.png';
import { Grid } from '@material-ui/core';
import Contact from './components/contact';
import ExplainerBox from './components/explainerBox';
import Landing from './components/landing';

class App extends Component {

    // referral.roadbotics.com/sender/receiver

    styles= {
        background: {
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            zIndex: -5
        },
        logo: {
            margin: '20px auto 0 auto',
            display: 'block',
            width: '90%',
            marginBottom: '7%'
        }
    };

    explainerText = "We want to share how you are making data-driven pavement decisions and how your friends can receive objective road assessments. Please enter your email, and your friend’s email to send the invitation."




    render() {
      let sender;
      let receiver;
      if(this.props.location.pathname.split('/')[1]) {
          sender = this.props.location.pathname.split('/')[1];
          receiver = this.props.location.pathname.split('/')[2];
      }
      return (
            <div>
                <img style={this.styles.background} src={background} alt={"background"}/>
                <div style={{position: 'absolute', background: 'rgba(0,0,0,.4)', width: '100vw'}}>
                    <Grid container>

                        <Grid item xs={4} lg={5}/>

                        <Grid item xs={4} lg={2}>
                            <img style={this.styles.logo} src={whitelogo} alt={"logo"}/>
                        </Grid>

                        <Grid item xs={4} lg={5}/>

                        {(sender && receiver) ?
                                <ReferredContainer sender={this.props.location.pathname.split('/')[1]} receiver={this.props.location.pathname.split('/')[2]}/>
                            :
                            <div>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Landing />
                                    </Grid>
                                    <Grid item xs={1} lg={4}/>
                                    <Grid item xs={10} lg={4}>
                                        <ExplainerBox text={this.explainerText}/>
                                    </Grid>
                                </Grid>
                            </div>
                        }

                        <br/>
                        <Grid item xs={12}>
                            <ReferBarContainer/>
                        </Grid>

                        <Grid item md={12}>
                            <Contact />
                        </Grid>
                    </Grid>
                </div>
            </div>
    );
  }
}

export default App;
