import React from 'react';
import { Button, withStyles, Typography, Hidden, Grid } from '@material-ui/core';
import OnePager from './onePager';

const styles = theme => ({
    fullBar: {
        background: 'rgba(0,0,0, .5)',
        width: '100vw',
        paddingTop: '2%',
        paddingBottom: '2%',
    },
    innerBar: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        // width: '50%'
    },
    text: {
        color: '#fff'
    },
    colorText: {
        color: '#fad201'
    },
    btn: {
        borderRadius: '35px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        textTransform: 'none'
    }
})



const Referred = (props) => {
    const {acceptReferral, sender, classes} = props;
    return (
        <div>
            <Grid container>
            <div className={classes.fullBar}>
                <div className={classes.innerBar}>
                    <Hidden mdDown>
                        <Typography className={classes.text} variant={"h3"} align={"center"}><strong className={classes.colorText}>{sender}</strong> has invited you <br/> to a Free RoadBotics Demo</Typography>
                        <br/>
                        <Typography className={classes.text} variant={"h6"} align={"center"}>RoadBotics provides <i className={classes.colorText}> fast, objective road assessments </i> using artificial intelligence (AI) and smartphones.</Typography>
                        <Typography className={classes.text} variant={"h6"} align={"center"}>Select a date and time below and a RoadBotics representative will reach out to you.</Typography>
                    </Hidden>

                    <Hidden mdUp>
                        <Grid item xs={12}>
                            <Typography className={classes.text} variant={"h4"} align={"center"}><strong className={classes.colorText}>{sender}</strong> has invited you <br/> to a Free RoadBotics Demo</Typography>
                            <br/>
                            <Typography className={classes.text} variant={"body1"} align={"center"}>RoadBotics provides <i className={classes.colorText}> fast, objective road assessments </i> using artificial intelligence (AI) and smartphones.</Typography>
                            <Typography className={classes.text} variant={"body1"} align={"center"}>Select a date and time below and a RoadBotics representative will reach out to you.</Typography>
                        </Grid>
                    </Hidden>
                        <br/>
                    <Button className={classes.btn} onClick={acceptReferral}>Select Date and Time</Button>
                </div>
            </div>
            <br/>
            <OnePager/>
            </Grid>
        </div>
    )
};

export default withStyles(styles)(Referred);
