import React from 'react';
import { Typography, withStyles, Grid, Hidden } from '@material-ui/core';
import gif from '../images/RoadWay_Points.gif';
import ExplainerBox from "./explainerBox";

const styles = theme => ({
    whole: {
        marginTop: '5%'
    },
    text: {
        color: '#fff'
    },
    textBlock: {

    },
    image: {
        boxShadow: '5px 10px 15px  #000',
        width: '100%',
        marginTop: '10%',
        marginBottom: '5%'
    }
});

const explainerText = (
    <div>
        <Typography style={{color: '#fff', fontSize: '1.2em'}} variant={"body1"}>Today, we help over 100 governments across the world make data-driven pavement decisions.
            <br/>
            <br/>
            Before RoadBotics, the City of Savannah relied on 18 interns over a 3-year period to complete an assessment of their 700-mile road network.
            With RoadBotics, the city received a comprehensive road condition assessment that was completed in just 3 months and saved the city 60% of the cost of the traditional approach.
            <br/>
            <br/>
            To see more RoadBotics customers, click <a style={{color: '#fad201'}} href="https://roadbotics.com/customers">here</a>.

        </Typography>
    </div>
)

const OnePager = (props) => {
    const { classes } = props;
    return (
        <div>
            <Grid className={classes.whole} container>
                <Grid item xs={1} md={1}/>
                <Grid item xs={10} md={4}>

                    <Hidden mdUp>
                        <Typography style={{color: '#fff', overflow: 'hidden', fontWeight: 700}} variant={"h5"} align={"center"}>Global Leader in Road Assessments</Typography>
                    </Hidden>
                    <Hidden smDown>
                        <Typography style={{color: '#fff', overflow: 'hidden'}} variant={"h2"}>Global Leader in Road Assessments</Typography>
                    </Hidden>
                    <br/>
                    <br/>
                    <ExplainerBox text={explainerText}/>

                </Grid>

                <Grid item md={1}/>

                <Grid item xs={12} md={5}>
                    <img className={classes.image} src={gif} alt={"gif"}/>
                </Grid>


                {/*<Grid item md={12}>*/}
                    {/*<HowItWorks />*/}
                {/*</Grid>*/}



                {/*<Grid item md={12}>*/}
                    {/*<Customers />*/}
                {/*</Grid>*/}

                {/*<Grid item md={12}>*/}
                    {/*<Contact />*/}
                {/*</Grid>*/}
            </Grid>
        </div>
    )
}

export default withStyles(styles)(OnePager);
