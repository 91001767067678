import React from 'react';
import Referred from '../components/referred'
import firebase from '../config';

class ReferredContainer extends React.Component{

    sendReadToFB = () => {

    let newReceiver = this.props.receiver.replace(/\./g, '-');
    let newSender = this.props.sender.replace(/\./g, '-');

    firebase.database().ref(`messages/${newSender}/${newReceiver}`).update({
        accepted: true
    })
    // fire firebase

    };

    acceptReferral = () => {
        this.sendReadToFB();
        window.open("https://app.hubspot.com/meetings/benjamin-utter", "_blank");
    };

    render(){
        return (
            <Referred acceptReferral={this.acceptReferral} receiver={this.props.receiver} sender={this.props.sender}/>
        )
    }
}

export default ReferredContainer
