import React from 'react';
import { Button, TextField, Card, CardHeader, CardActions, CardContent } from '@material-ui/core'

const Login = (props) => {
    const { handleEmail, handlePassword, email, password, submit } = props;
    return (

            <Card style={{borderBottom: '10px solid #fad201'}}>
                <CardHeader title={"Login"}/>
                <form onSubmit={submit}>
                <CardContent>
                        <TextField label={"Email"} value={email} onChange={handleEmail}/>
                        <TextField style={{marginLeft: '5%'}} label={"Password"} type={"password"} value={password} onChange={handlePassword}/>
                </CardContent>
                <CardActions>
                    <Button type="submit"> Login </Button>
                </CardActions>
                </form>
            </Card>
    )
};

export default Login;
