import React from 'react';
import firebase from '../config';
import { Table, TableCell, TableRow, TableBody, TableHead, Checkbox } from '@material-ui/core';
import { Link } from 'react-router-dom';
import LoginContainer from "./loginContainer";

class ReadPortalContainer extends React.Component{
    constructor() {
        super();
        this.state = {
            senders: [],
            authed: false
        }
    }


    componentDidMount(){
        let that = this;
        firebase.database().ref('/messages').on('value', (snapshot) => {

            let arry = [];
            if(snapshot.val()) {
                const snap = snapshot.val();
                Object.keys(snap).forEach(giver => {
                    // arry.push(snap[giver])
                    Object.keys(snap[giver]).forEach(receiver => {
                        arry.push(snap[giver][receiver])
                    })
                })

                that.setState({
                    senders: arry
                })
                // that.setState({
                //     contents: snapshot.val()
                // })
            }
        })
    }

    login = (email, password) => {
        firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
            this.setState({
                ...this.state,
                authed: true
            })
        }).catch(error => {
            alert(`ERROR: ${error.message}`)
        })
    }



    render(){
        if(this.state.authed) {
            return (
                <div style={{marginTop: '5%', borderBottom: '10px solid #fad201'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Sender</TableCell>
                                <TableCell>Receiver</TableCell>
                                <TableCell>Message</TableCell>
                                <TableCell>Accepted</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {this.state.senders.map(rec => {
                                return (
                                    <TableRow key={rec.sender}>
                                        <TableCell>{rec.sender}</TableCell>
                                        <TableCell>{rec.receiver}</TableCell>
                                        <TableCell>{rec.message}</TableCell>
                                        <TableCell><Checkbox checked={rec.accepted} disabled onChange={null}/></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    <Link to={"/"} > Not a RoadBotics Employee?</Link>
                </div>
            )
        } else {
            return (
                <div>
                    <LoginContainer login={this.login} />
                    <Link to={"/"} > Not a RoadBotics Employee? </Link>
                </div>)
        }
    }
}

export default ReadPortalContainer;
