import React from 'react';
import { withStyles, Typography } from '@material-ui/core';










const styles = theme => ({
    fullBar: {
        background: 'rgba(0,0,0,.5)',
        marginTop: '5%',
        width: '100%'
        // borderTop: '1px solid #fad201',
        // borderBottom: '1px solid #fad201'
    },
    content: {
        width: '40%',
        paddingTop: '3%',
        paddingBottom: '3%',
        // height: '150px',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    yours: {
        paddingRight: '2%',
        color: '#fff',
        width: '40%',

        // marginTop: '-3%'
        // bottom: 0
    },
    theirs: {
        color: '#fff',
        width: '40%',
        paddingRight: '2%',
        // marginBottom: '15%',
        float:'right'

    },
    message: {
        paddingRight: '2%',
        width: '100%',
        color: '#fff',
        // marginTop: '-5%'

    },
    messageInput: {
        backgroundColor: 'rgba(52,51,50,.5)'
    },
    input: {
        color: '#fff'
    },
    header: {
        // borderRight: '10px solid #fad201',
        // borderLeft: '10px solid #fad201',
        color: '#fff',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '3%',
        fontWeight: 700,
        marginTop: '2%'
    },
    btn: {
        marginTop: '5%',
        marginBottom: '3%'
    }
});




const Landing = (props) => {
    const {classes} = props;
    return (
        <div>
            <Typography className={classes.header}  variant={"h2"} align={"center"}>Invite a Friend <br/> To A <strong style={{color: '#fad201'}}> RoadBotics </strong>Demo</Typography>
        </div>
    )
};

export default withStyles(styles)(Landing)
