import React from 'react';
import { TextField, Button, withStyles, Typography, Dialog, DialogContent, DialogTitle } from '@material-ui/core';

const styles = theme => ({
    fullBar: {
        background: 'rgba(0,0,0,.5)',
        marginTop: '5%',
        width: '100%'
        // borderTop: '1px solid #fad201',
        // borderBottom: '1px solid #fad201'
    },
    content: {
        width: '40%',
        paddingTop: '3%',
        paddingBottom: '3%',
        // height: '150px',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    yours: {
        paddingRight: '2%',
        color: '#fff',
        width: '40%',

        // marginTop: '-3%'
        // bottom: 0
    },
    theirs: {
        color: '#fff',
        width: '40%',
        paddingRight: '2%',
        // marginBottom: '15%',
        float:'right'

    },
    message: {
        paddingRight: '2%',
        width: '100%',
        color: '#fff',
        // marginTop: '-5%'

    },
    messageInput: {
        backgroundColor: 'rgba(52,51,50,.5)',
        color: '#fff'
    },
    input: {
        color: '#fff'
    },
    header: {
        // borderRight: '10px solid #fad201',
        // borderLeft: '10px solid #fad201',
        color: '#fff',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '3%',
        fontWeight: 700,
        marginTop: '2%'
    },
    btn: {
        marginTop: '5%',
        marginBottom: '3%'
    }
});




const ReferBar = (props) => {
    const {open, classes, submit, message, handleMessage, from, handleFrom, name, handleName} = props;
    return (
        <div>
            <div className={classes.fullBar}>
                <div className={classes.content}>
                    <Typography className={classes.input} variant={"h5"} align={"center"}>Invite A Friend</Typography>

                    <TextField
                        className={classes.yours}
                        label="Your Email"
                        value={from}
                        onChange={handleFrom}
                        margin="normal"
                        InputLabelProps={{className: classes.input}}
                        inputProps={{style: {color: '#fff'}}}
                    />

                    <TextField
                        className={classes.theirs}
                        label="Their Email"
                        value={name}
                        onChange={handleName}
                        margin="normal"
                        InputLabelProps={{className: classes.input}}
                        inputProps={{style: {color: '#fff'}}}

                    />
                    <br/>

                    <TextField
                        className={classes.message}
                        label="Send them a message... (optional)"
                        value={message}
                        onChange={handleMessage}
                        multiline
                        rows="4"
                        margin="normal"
                        InputLabelProps={{className: classes.input}}
                        InputProps={{className: classes.messageInput}}

                    />
                    <br/>
                    <Dialog open={open}>
                        <DialogTitle> <Typography> Thanks for submitting! </Typography> </DialogTitle>
                        <DialogContent> Thank you for submitting your referral and spreading the word! We're excited that you want to tell your friends about RoadBotics. </DialogContent>

                    </Dialog>

                    <Button className={classes.btn} variant={"outlined"} onClick={submit}>Submit</Button>
                </div>
            </div>
        </div>
    )
};

export default withStyles(styles)(ReferBar)
